import React, { FC } from 'react';
import { graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import styled from 'styled-components';

import SEO from 'src/components/SEO';
import Layout from 'src/components/Layout';
import Content from 'src/components/Content';

const StyledContent = styled(Content)`
  padding-bottom: 24px;
  padding-top: 24px;
`;

const ImageWrapper = styled.div`
  max-width: 640px;
`;

const EUPage: FC = () => (
  <Layout>
    <StyledContent>
      <p>
        UAB &quot;Omniget&quot; įgyvendina projektą, kuriuo siekia sukurti
        sprendimą, skirtą e-komercijos produktų tvarkymui. Projekto tikslas yra
        sukurti &quot;AI Powered E-Komercijos produktų tvarkymo sprendimą&quot;,
        naudojant Dirbtinio Intelekto technologijas. Šis sprendimas kategorizuos
        produktus, tvarkys nuotraukas, sudėlios produktų savybes, rekomenduos
        susijusius produktus ir generuos produkto aprašymus. Sprendimas atitinks
        šiuolaikines elektroninės prekybos tendencijas ir leis el. parduotuvėms
        konkuruoti efektyviau rinkoje. Bendra projekto vertė 107 856 Eur, iš
        kurių 86 000 Eur skirta iš Ekonomikos gaivinimo ir atsparumo didinimo
        priemonės lėšų. Projekto įgyvendinimo trukmė 2024.06 – 2025.06.
      </p>
      <ImageWrapper>
        <StaticImage
          src="../assets/images/eu-funding.jpg"
          alt="OmniGet projekta finansuoja Europos Sąjunga"
          layout="fullWidth"
        />
      </ImageWrapper>
    </StyledContent>
  </Layout>
);

export default EUPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export const Head = () => (
  <SEO
    title="EU Funding | OmniGet"
    description="OmniGet EU Funding information."
  />
);
